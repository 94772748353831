<template>
  <div id="page-user-view">
    <div v-if="showActivities">
      <vx-card>
        <h2>{{ child.fullName }}'s Learning Stories</h2>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div v-for="activity in activities" :key="activity._id" class="ph-img-list">
            <a @click="activitySelect(activity._id)">
              <img :src="activity.photos.length  ? activity.photos[0].photo : $defaultImage "
                   class="responsive h-64 object-cover">
            </a>
            <span class="mt-5 block" @click="activitySelect(activity._id)">{{ activity.name }}</span>
          </div>
        </div>
        <div class="pagination-div flex justify-center float-none mt-5" v-if="totalPages">
          <paginate
            :page-count="totalPages"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
          ></paginate>
        </div>

      </vx-card>
    </div>
    <div v-if="showActivityDetail">
      <h2 class="mx-10">Learning stories</h2>
      <vx-card>
        <div class="vx-breadcrumb mb-5 md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <a class="" @click="goBack"> Learning stories</a>
              <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                          points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
            </li>
            <li class="inline-flex items-center">
              <a class=""> {{ moment(selectedActivity.updatedAt) + ' - ' + selectedActivity.name }}</a>
            </li>
          </ul>
        </div>
        <div class="flex items-center mb-5">
          <h2 class="mb-0">{{ selectedActivity.name }}</h2>
        </div>
        <p class="full-width-detail">{{ selectedActivity.description }}</p>
        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center">
              <strong>Core Skill</strong>
              <img :src="selectedActivity.coreSkills.icon ? selectedActivity.coreSkills.icon : $defaultImage "
                   :alt="selectedActivity.coreSkills.name" class="h-16 object-contain block mx-auto mt-5">
                   {{selectedActivity.coreSkills.name}}
            </li>
            <li class="px-5 md:px-10 text-center">
              <strong>Ways of Learning</strong>
              <img
                :src="selectedActivity.waysOfLearnings.icon ? selectedActivity.waysOfLearnings.icon : $defaultImage "
                :alt="selectedActivity.waysOfLearnings.name" class="h-16 object-contain block mx-auto mt-5">
                {{selectedActivity.waysOfLearnings.name}}
            </li>
            <li class="pl-5 md:pl-10 text-center">
              <strong>STREAM</strong>
              <img :src="selectedActivity.streams.icon ? selectedActivity.streams.icon : $defaultImage "
                   :alt="selectedActivity.streams.name" class="h-16 object-contain block mx-auto mt-5">
                   {{selectedActivity.streams.name}}
            </li>
          </ul>
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Play with Purpose. (Your Intentionality)</h4>
          {{ selectedActivity.purposeStatement }}
        </div>
        <div class="mt-10">
          <h4 class="font-bold">How has {{ child.fullName }} changed? (Assessment of Learning)</h4>
          {{ selectedActivity.children.howHasChanged }}
        </div>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div v-for="photo in selectedActivity.photos" :key="photo._id" class="ph-img-list">
            <a href="javascript::void(0)">
              <img :src="photo.src" class="responsive h-64 object-cover">
            </a>
          </div>
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Child's Voice</h4>
          {{ selectedActivity.children.childVoice }}
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Core skill reflection</h4>
          {{ selectedActivity.children.coreSkillReflection }}
        </div>

      </vx-card>
      <vx-card style="margin-top: 30px" v-if="selectedActivity.followUp">
        <h4 class="font-bold">FOLLOW UP THE LEARNING: {{ selectedActivity.followUp.name }} </h4>
        <p>{{ selectedActivity.followUp.description }}</p>

        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center">
              <strong>Core Skill</strong>
              <img
                :src="selectedActivity.followUp.coreSkills.icon ? selectedActivity.followUp.coreSkills.icon : $defaultImage "
                :alt="selectedActivity.followUp.coreSkills.name" class="h-16 object-contain block mx-auto mt-5">
                {{selectedActivity.followUp.coreSkills.name}}
            </li>
            <li class="px-5 md:px-10 text-center">
              <strong>Ways of Learning</strong>
              <img
                :src="selectedActivity.followUp.waysOfLearnings.icon ? selectedActivity.followUp.waysOfLearnings.icon : $defaultImage "
                :alt="selectedActivity.followUp.waysOfLearnings.name" class="h-16 object-contain block mx-auto mt-5">
                 {{selectedActivity.followUp.waysOfLearnings.name}}
            </li>
            <li class="pl-5 md:pl-10 text-center">
              <strong>STREAM</strong>
              <img
                :src="selectedActivity.followUp.streams.icon ? selectedActivity.followUp.streams.icon : $defaultImage "
                :alt="selectedActivity.followUp.streams.name" class="h-16 object-contain block mx-auto mt-5">
                 {{selectedActivity.followUp.streams.name}}
            </li>
          </ul>
        </div>

        <div class="mt-10">
          <h4 class="font-bold">Teacher's Comment</h4>
          {{ selectedActivity.followUp.followUpCompletedText }}
        </div>

      </vx-card>
      <vx-card class="mt-10">
        <h2>Approved Learning Framework: Where can this play with purpose take {{ child.fullName }}</h2>
        <vs-row>
          <vs-col
            v-for="(category, key) in categories"
            :key="key"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
            class="eylf-col px-0 md:pr-10 border-b-0 mb-5 md:mb-10"
          >
            <h4 class="font-bold mb-5">Learning Framework {{ category.title }}</h4>
            <ul>
              <li v-for="(outcome,index)  in category.eylfChecklists " :key="index+0.2" class="flex items-center mb-3">
                <img :src="isEylfExist(outcome._id) ? $defaultIconTick : $defaultIconMinus " class="mr-5">
                <strong class="mr-3">{{category.title | singularize}} {{ index + 1 }}: </strong>
                {{ outcome.title }}
                <vx-tooltip :text="outcome.description" color="#000000" position="right" class="inline-block">
                  <a href="#" class="ml-3">
                    <feather-icon icon="InfoIcon" style="width: 15px;"/>
                  </a>
                </vx-tooltip>
              </li>
            </ul>
          </vs-col>

        </vs-row>
      </vx-card>

    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import moment from "moment";
import Vue from 'vue'
import VueLodash from 'vue-lodash'
import _ from 'lodash'

Vue.use(VueLodash, {name: 'custom', lodash: _})
export default {
  data() {
    return {
      itemPerPage: 15,
      page: 1,
      totalPages: 0,
      activities: [],
      showActivities: true,
      showActivityDetail: false,
      selectedActivity: '',
      categories: []
    }
  },
  props: {
    child: ''
  },
  methods: {
    ...mapActions("activity", [
      "fetchCompletedActivityByChildId",
      "fetchActivityByActivityIdAndChildId"
    ]),
    ...mapActions("eylfCategory", [
      "fetchEYLFCategoryWithFrameworkByCenterId"
    ]),
    goBack() {
      this.showActivities = true;
      this.showActivityDetail = false;
    },
    moment(date) {
      return moment(date).format('DD/MM/YY')
    },
    isEylfExist(key) {
      return _.includes(this.selectedActivity.eylfFrameworks, key);
    },
    async getActivities(childId, page) {
      this.$vs.loading();
      const data = {
        childId: childId,
        page: page,
        limit: this.itemPerPage,
      }
      await this.fetchCompletedActivityByChildId(data).then((response) => {
        this.$vs.loading.close();
        if (response.data.data.docs.length) {
          this.activities = response.data.data.docs;
          this.totalPages = Math.ceil(response.data.data.pagination.total / this.itemPerPage)
        }

      })
      this.$vs.loading.close();

    },
    handleChangePage(page) {
      this.getActivities(this.childId, page);
    },
    async activitySelect(activityId) {
      const data = {
        activityId: activityId,
        childId: this.childId
      }
      this.showActivities = false;

      await this.fetchActivityByActivityIdAndChildId(data).then((response) => {
        this.selectedActivity = response.data.data;
        this.selectedActivity.photos = response.data.data.photos.map((item) => {
          if (item.taggedTo.includes(this.childId)) {
            return {
              thumb: item.photo,
              src: item.photo,
              srcset: item.photo
            };
          }
        }).filter((item) => item !== undefined);
        this.showActivityDetail = true;
      })
    },
    async getEylfChecklist(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId)
        .then((checklist) => {
          this.categories = checklist.data.data.eylfCategories;
        });
    },
  },
  mounted() {
    this.getActivities(this.childId, this.page);
    this.getEylfChecklist(this.child.learningCenterId);
  },
  computed: {
    childId() {
      return this.$route.params.childId;
    },
  },
  filters: {
    singularize: function (value) {
      return value[value.length -1] === 's' ? value.slice(0,-1): value
    }
  }
}
</script>
<style lang="scss">

.vs-tooltip {
  z-index: 100000;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}
</style>
